import Sketch from "./fake3d/src";
const gl = document.getElementById("gl");
const original = document.getElementById("original");
const zdepth = document.getElementById("zdepth");
const overlay = document.getElementById("overlay");
const mask = document.getElementById("mask");
gl.dataset.imageOriginal = original.src;
gl.dataset.imageDepth = zdepth.src;
gl.dataset.imageOverlay = overlay.src;
gl.dataset.imageMask = mask.src;
const anim = new Sketch();
const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");
mediaQuery.addEventListener("change", () => {
  if (mediaQuery.matches) {
    anim.disable();
  } else {
    anim.reenable();
  }
});
